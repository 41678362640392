<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        In 2001, Nescafe launched a self-heating can of coffee, called "Hot When You Want". To heat
        up the coffee, a button was pressed which mixed the heating ingredients in a shell outside
        the can containing the coffee. A single step mixed calcium oxide and water to produce
        calcium hydroxide and generate heat according to the reaction:
      </p>

      <p class="mb-5 pl-4">
        <chemical-latex content="CaO(s) + H2O(l) -> Ca(OH)2(aq)" />
        <stemble-latex content="$\qquad\Delta\text{H}=-82.0\,\text{kJ/mol}$" />
      </p>

      <p class="mb-2">
        a) Assuming that the heat capacity for coffee is the same as that of water,
        <stemble-latex content="$4.184\,\text{J}\,\text{g}^{-1}\,\text{K,}$" />
        calculate the energy (in J) needed to warm up
        <number-value :value="volume" unit="\text{cm}^3" />
        of coffee by
        <number-value :value="tempChange" unit="^\circ\text{C.}" />
      </p>

      <calculation-input
        v-model="inputs.heatRequired"
        prepend-text="$\text{q:}$"
        append-text="$\text{J}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Using the value determined in part a), calculate the <b>minimum</b> mass of CaO that
        would be required in the can for it to function as specified.
      </p>

      <calculation-input
        v-model="inputs.minimumMass"
        prepend-text="$\text{Mass CaO:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'UOttawaPrelab2Q4',
  components: {
    NumberValue,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        heatRequired: null,
        minimumMass: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    tempChange() {
      return this.taskState.getValueBySymbol('tempChange').content;
    },
  },
};
</script>
